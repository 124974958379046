const autoScrollTo = ({index = 0, currentSection, innerHeight, setCurrent = () => {}, current}) => {
    const screenHeight = innerHeight;
    currentSection.current = index;
    setCurrent(index)
    window.scrollTo({
        top: (index) * screenHeight,
        behavior: 'smooth',
    });
}

const handleScroll = ({innerHeight, currentSection, lastScrollPosition, setCurrent = () => {}, current}) => {
    const y = window.scrollY;
    if (y !== lastScrollPosition.current) {
        const screenHeight = innerHeight;
        const oldSection = currentSection.current;
        const nextTreshold = (screenHeight*oldSection) + (screenHeight*0.15);
        const previousTreshold = (screenHeight*oldSection) - (screenHeight*0.85);

        let newSection = currentSection.current;
        if (y >= nextTreshold) {
            newSection = Math.trunc(y/screenHeight)+1;
            currentSection.current = newSection;
        } else if (y <= previousTreshold) {
            newSection = Math.trunc(y/screenHeight);
            currentSection.current = newSection;
        }
        setCurrent(newSection)
        lastScrollPosition.current = y;
    }
    
};

const addScrollListener = ({innerHeight, currentSection, lastScrollPosition, setCurrent, onChangedInnerHeight = false}) => {
    window.removeEventListener('scroll', () => {});    
    if (onChangedInnerHeight) {
        handleScroll({innerHeight:innerHeight, currentSection:currentSection, lastScrollPosition:lastScrollPosition, setCurrent:setCurrent});
    }
    window.addEventListener('scroll', () => {handleScroll({innerHeight:innerHeight, currentSection:currentSection, lastScrollPosition:lastScrollPosition, setCurrent:setCurrent})}, false);

}

const addResizeListener = ({setInnerHeight = () => {}}) => {
    let timeout;
    window.addEventListener('resize', () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setInnerHeight(window.innerHeight)
      }, 200);
    });
}


module.exports = {autoScrollTo, handleScroll, addScrollListener, addResizeListener};