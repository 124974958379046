import { useEffect, useState, useRef } from 'react';
import { autoScrollTo, addScrollListener, addResizeListener } from '../../modules/scrolling.module';
import './Main.scss';
import Navbar from '../../components/Navbar/Navbar';
import Home from '../../components/Home/Home';
import Partners from '../../components/Partners/Partners';
import Team from '../../components/Team/Team';
import Values from '../../components/Values/Values';
import Cirrus from '../../components/Cirrus/Cirrus';

export default function Main() {
    const [current, setCurrent] = useState(0)
    let currentSection = useRef(0);
    let lastScrollPosition = useRef(0)
    const [innerHeight, setInnerHeight] = useState(window.innerHeight); // On fixe pour protéger des bugs liés aux bars de recherche qui disparraissent
    
    const goTo = (index = 0) => {autoScrollTo({index:index, currentSection:currentSection, innerHeight:innerHeight, setCurrent:setCurrent, current:current });}

    useEffect(() => {
        addScrollListener({innerHeight:innerHeight, currentSection:currentSection, lastScrollPosition:lastScrollPosition, setCurrent:setCurrent, current:current, onChangedInnerHeight: true});
    }, [innerHeight]);
    useEffect(() => {
        //addScrollListener({innerHeight:innerHeight, currentSection:currentSection, lastScrollPosition:lastScrollPosition, setCurrent:setCurrent});
        addResizeListener({setInnerHeight:setInnerHeight});
    },[]);
    
    return (
        <div className="Main">
            <Navbar current={current}  
                    goTo={goTo} />
            <Home   goTo={goTo} />
            <Cirrus current={current} />
            <Values current={current} />
            <Team />
            <Partners />
        </div>
    );
}